import React from "react"
import { graphql, Link } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as style from './404.module.css'

const NotFoundPage = ({ data }) => {
	const { edges } = data.allMarkdownRemark

	return (
		<Layout>
			<SEO title="404: Not found" />
			<header className={ style.Header }>
				<h1>404<br />We can’t find that scene.</h1>
			</header>
			<section className={ style.Listing }>
				<h2>Are you looking for one of these?</h2>
				<ul>
					{
						edges.map(edge => {
							const { frontmatter } = edge.node
							return (
								<li><Link to={ frontmatter.path }>{ frontmatter.title } ({ frontmatter.year })</Link></li>
							)
						})
					}
				</ul>
			</section>
		</Layout>
	)
}

export const query = graphql`
	query Entries {
		allMarkdownRemark(
			sort: { order: ASC, fields: [frontmatter___title] }
			limit: 1000
		) {
			edges {
				node {
					frontmatter {
						director
						index
						path
						poster
						title
						url
						year
					}
				}
			}
		}
	}
`

export default NotFoundPage
